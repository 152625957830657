import { Button, Col, Input, message, Row, Table, Select, Divider } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { createRef, useEffect, useState } from "react";

export default function FilterComponent({
  filterOptions,
  defaultFilterOption,
  onFilter,
  filterState,
  setFilterState,
}) {
  const { Search } = Input;
  const { Option } = Select;
  const [searchBoxState, setSearchBoxState] = useState({
    value: "",
    placeholder: `Search`,
  });
  //   const [filterState, setFilterState] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  const searchRef = createRef();

  useEffect(() => {
    setSearchBoxState((state) => {
      return {
        value: defaultFilterOption,
        placeholder: `Search for ${
          filterOptions.filter(
            (option) => option.value === defaultFilterOption
          )[0].label
        }`,
      };
    });
  }, [defaultFilterOption, filterOptions]);

  const options = filterOptions.map((option) => {
    return (
      <Option key={option.value} value={option.value} label={option.label}>
        {option.label}
      </Option>
    );
  });
  const filterList = (
    <Select
      defaultValue={defaultFilterOption}
      onSelect={(value, options) => onFilterSelect(value, options)}
      className="select-before"
    >
      {options}
    </Select>
  );
  const onFilterSelect = (val, options) => {
    console.log(val, options);
    setSearchBoxState({
      value: val,
      placeholder: `Search for ${options.label}`,
    });
    const newFilterState = {};
    newFilterState[val] = Object.values(filterState)[0];
    setFilterState(newFilterState);
    searchRef.current.focus();
  };

  const onFilterChange = (value) => {
    const val = searchBoxState.value;
    const param = {};
    param[val] = value;
    setFilterState(param);
  };
  const onFilterEnter = async () => {
    onFilter({
      ...filterState,
    });

    // fetch({
    //   per_page: 7,
    //   ...filterState,
    // });
  };

  return (
    <>
      <Search
        ref={searchRef}
        placeholder={searchBoxState.placeholder}
        style={{ border: "none" }}
        loading={searchLoading}
        addonBefore={filterList}
        enterButton="Filter"
        autoFocus={true}
        onChange={(e) => onFilterChange(e.target.value)}
        onPressEnter={onFilterEnter}
        onSearch={onFilterEnter}
      />
    </>
  );
}
