import { Menu } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

function ManuItems() {
  let location = useLocation();
  console.log(location, location.pathname);

  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["/dashboard"]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/dashboard" icon={<UserOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="/lent" icon={<VideoCameraOutlined />}>
          <Link to="/lent">Lent</Link>
        </Menu.Item>
        <Menu.Item key="/categories" icon={<VideoCameraOutlined />}>
          <Link to="/categories">Categories</Link>
        </Menu.Item>
        <Menu.Item key="/items" icon={<UploadOutlined />}>
          <NavLink to="/items">Items</NavLink>
        </Menu.Item>
        <Menu.Item key="/employees" icon={<UploadOutlined />}>
          <NavLink to="/employees">Employees</NavLink>
        </Menu.Item>
        <Menu.Item key="/users" icon={<UploadOutlined />}>
          <NavLink to="/users">Users</NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default ManuItems;
