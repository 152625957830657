import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";
import { login } from "../services/LoginService";
import { useContext, useState } from "react";
import { AuthedContext } from "../contexts/AuthedContext";

function Login() {
  const { setAuthed } = useContext(AuthedContext);
  const [loading, setLoading] = useState(false);

  const onFinish = async (credentials) => {
    setLoading(true);
    const token = await login(credentials)
      .catch((error) => {
        message.error(`${error.response.data}`);
      })
      .finally(() => {
        setLoading(false);
      });

    setAuthed(token);
  };

  return (
    <Layout
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="site-layout-background"
        style={{
          //   margin: "0 auto",
          padding: 24,
          minHeight: 280,
          width: 350,
          backgroundColor: "#fff",
          boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: 20 }}>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Emblem_of_Maldives.svg/375px-Emblem_of_Maldives.svg.png"
            alt="Government Emblem"
            style={{ width: "70px", padding: "10px" }}
          />
          <br />
          <strong>Inventory Management</strong>
          <br />
          {process.env.REACT_APP_ORG_NAME}
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Username is required",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              type="email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loading}
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
}

export default Login;
