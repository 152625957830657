import React, { useEffect, useState } from 'react';
import { getList } from '../services/list';
import { Card, Empty, message } from 'antd';
import AdminLayout from './AdminLayout';


export default function Dashboard(){

    const [list, setList] = useState([]);

    useEffect(() => {
        let mounted = true;
        getList("categories")
            .then(items => {
                if(mounted) {
                    setList(items.data)
                }
            })
            .catch(error => {
                message.error(`${error.statusCode}: ${error.message}`);
            })
        return () => mounted = false;
      }, []
    );

    let itemList = null;
    if( list.length === 0 )
        itemList = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    else{
        itemList = list.map( (item, i) => {
            return <li key={i}>{item.name}</li>
        })
    }

    return(
        <Card>
        {/* <AdminLayout> */}
            <h1>Dashboard</h1> 
            <ul>
               { itemList }
            </ul>
        {/* </AdminLayout> */}
        </Card>
    );
}