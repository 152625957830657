import { Button, } from "antd";
import { useContext, } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { AuthedContext } from "../contexts/AuthedContext";

import {
    UnlockOutlined,
    
  } from "@ant-design/icons";
import { logout } from "../services/authService";


function Logout(){

    let history = useHistory();
    const {setAuthed} = useContext(AuthedContext)

    const handleLogout = async () => {
        try{
            await logout()
            setAuthed()
            history.push('/login')
        }
        catch(error){
            console.error(error)
        }
    }

    return (
        <Button icon={<UnlockOutlined />} onClick={() => handleLogout() }>Logout</Button>
    );
}


export default withRouter(Logout);