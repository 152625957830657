import axios from "axios"


export function getList(resource, params={}) {
    // return axios.get('http://localhost:1337/categories')
    return axios.get(`${process.env.REACT_APP_BASE_URL}/${resource}`, {params: params})
      .then(res => {
            return res.data
        })
        .catch( err => {
            console.log(err)
            return Promise.reject(err.response.data)
        })
  }