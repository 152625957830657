import { useEffect, useState } from "react";
import { LoadingOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Row, Table } from "antd";
import { getList } from "../services/list";
import FilterComponent from "./sub/FilterComponent";
import EmployeeFormDrawer from "./sub/EmployeeFormDrawer";

const filterOptions = [
  {
    value: "filter[nic]",
    label: "NIC",
  },
  {
    value: "filter[name]",
    label: "Name",
  },
];

export default function Employees() {
  const [state, setState] = useState({
    loading: false,
    pagination: {},
    items: [],
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [filterState, setFilterState] = useState({});

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "NIC",
      dataIndex: "nic",
      key: "nic",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "is_employed",
      key: "is_employed",
      render: (is_employed) => {
        return <>{is_employed ? "Active" : "In-active"}</>;
      },
    },
    {
      title: "",
      key: "action",
      className: "has-actions",
      render: (record) => (
        <>
          <Button.Group className="actions">
            <Button
              type="link"
              onClick={(e) => handleFormDrawerOpenForUpdate(record)}
            >
              <EditOutlined />
              Edit
            </Button>
            <Button type="link" onClick={(e) => console.log(record)}>
              <EditOutlined />
              Delete
            </Button>
          </Button.Group>
        </>
      ),
    },
  ];

  const tableLoading = {
    spinning: state.loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  };

  const fetch = async (params = {}) => {
    let mounted = true;
    setState((prev) => {
      return { ...prev, loading: true };
    });
    getList("employees", { include: "borrows", sort: "-id", ...params })
      .then((items) => {
        if (mounted) {
          setState((prev) => {
            return {
              ...prev,
              items: items.data,
              pagination: {
                current: items.current_page,
                pageSize: items.per_page,
                per_page: items.per_page,
                total: items.total,
              },
            };
          });
        }
      })
      .catch((error) => {
        message.error(`${error.statusCode}: ${error.message}`);
      })
      .finally(() => {
        setState((prev) => {
          return { ...prev, loading: false };
        });
      });
    return () => (mounted = false);
  };

  useEffect(() => {
    fetch({
      per_page: 7,
    });
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setState((prev) => {
      return {
        ...prev,
        pagination: pagination,
      };
    });

    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const handleFormDrawerOpen = () => {
    setUpdate(false);
    setSelectedEmployee({});
    setDrawerOpen(true);
  };

  const handleFormDrawerClose = () => {
    setDrawerOpen(false);
    setUpdate(false);
    setSelectedEmployee({});
    fetch(state.pagination);
  };

  const handleFormDrawerOpenForUpdate = (record) => {
    setUpdate(true);
    setSelectedEmployee(record);
    setDrawerOpen(true);
  };

  return (
    <>
      <h1>Employees</h1>
      <Row style={{ marginBottom: "1rem" }}>
        <Col xs={8}>
          <FilterComponent
            filterOptions={filterOptions}
            defaultFilterOption="filter[name]"
            onFilter={fetch}
            filterState={filterState}
            setFilterState={setFilterState}
          />
        </Col>
        <Col xs={10} />
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button
            type="secondary"
            style={{ fontWeight: "bold" }}
            onClick={() => handleFormDrawerOpen()}
          >
            <PlusOutlined />
            Add New
          </Button>
        </Col>
      </Row>
      <Table
        rowKey="id"
        dataSource={state.items}
        columns={columns}
        loading={tableLoading}
        pagination={state.pagination}
        onChange={handleTableChange}
      />

      <EmployeeFormDrawer
        onClose={handleFormDrawerClose}
        visible={drawerOpen}
        update={update}
        employee={selectedEmployee}
      />
    </>
  );
}
