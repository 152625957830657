import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { apiPost } from "../../services/postService";

export default function EmployeeFormDrawer({
  onClose,
  visible,
  update,
  employee,
}) {
  const [form] = Form.useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  const title = update ? "Updating Employee" : "Adding New Employee";

  useEffect(() => {
    setState(employee);
    console.log(employee);
    form.resetFields();
    (() => {
      form.setFieldsValue(employee);
    })();
  }, [setState, employee, form]);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      let url = "employees";
      if (update) {
        values = { ...values, _method: "PATCH" };
        url = `employees/${state.id}`;
      }
      const res = await apiPost(url, values);
      console.log(res);
      message.success("Employee saved!");
      form.resetFields();
      onClose();
    } catch (error) {
      Object.keys(error.errors).forEach((key) => {
        form.setFields([
          {
            name: key,
            errors: [error.errors[key]],
          },
        ]);
      });
      message.error(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Drawer
        title={title}
        width={600}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={state}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Full Name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  placeholder="Ahmed Mohamed"
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, name: e.target.value };
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="nic"
                label="NIC"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  placeholder="A012345"
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, nic: e.target.value };
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="mobile"
                label="Mobile No"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  placeholder="7123456"
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, mobile: e.target.value };
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  placeholder="ahmed.mohamed@example.com"
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, email: e.target.value };
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {update === true && (
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="is_employed"
                    label="Employment Status"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio value={1}>Active</Radio>
                      <Radio value={0}>In-active</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
