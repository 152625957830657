import { Button, Col, Drawer, Form, Input, message, Row } from "antd";
import { useEffect, useState } from "react";
import { apiPost } from "../../services/postService";
import Loading from "../Loading";

export default function ReturnDrwaer({ onClose, visible, item }) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [returned, setReturned] = useState();
  const [localItem, setLocalItem] = useState({});

  const { form } = Form.useForm();

  useEffect(() => {
    setState({
      borrow_id: item.id,
    });
  }, [setState, item]);

  useEffect(() => {
    const ret = item.returned_at === null;
    setReturned(!ret);
  }, [setReturned, item]);

  useEffect(() => {
    setLocalItem(item.item);
  }, [setLocalItem, item]);

  const handleReturn = async () => {
    if (state.borrow_id === undefined)
      return message.error("Some errors with loading the borrow record");

    setLoading(true);

    try {
      const res = await apiPost(`borrows/${state.borrow_id}/return`, state);
      setReturned(true);
      // form.resetFields();
      onClose();
    } catch (error) {
      Object.keys(error.errors).forEach((key) => {
        form.setFields([
          {
            name: key,
            errors: [error.errors[key]],
          },
        ]);
      });
      message.error(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Drawer
        title="Return item"
        width={600}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
      >
        {loading && <Loading />}
        {Object.keys(item).length > 0 && (
          <div>
            Item: {item.item.name}, Borrowd By: {item.employee.name},{" "}
            {item.request_confirmed_at}
          </div>
        )}

        {returned ? (
          <div>Returned</div>
        ) : (
          <Form form={form}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="return_notes">
                  <Input.TextArea
                    onChange={(e) =>
                      setState((state) => {
                        return { ...state, return_notes: e.target.value };
                      })
                    }
                    placeholder="Return notes (for example if there's damage)"
                  />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 16 }}>
                  <Button
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => handleReturn()}
                  >
                    Process Return
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>
    </>
  );
}
