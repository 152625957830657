import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { getList } from "../../services/list";
import { apiPost } from "../../services/postService";
import moment from "moment";

export default function AddItemDrawer({ onClose, visible, update, item }) {
  const [form] = Form.useForm();
  const formRef = useRef();

  const [state, setState] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const title = update ? "Updating Item" : "Adding New Item";

  useEffect(() => {
    item.purchased_on = moment(item.purchased_on);
    setState(item);
    console.log("item", item);
    form.resetFields();

    (() => {
      form.setFieldsValue(item);
    })();
  }, [setState, item, form]);

  // useEffect(() => {
  //   const fetchCats = () => {
  //     getList("categories")
  //       .then((res) => {
  //         setCategories(res.data);
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //     return () => {};
  //   };
  //   fetchCats();
  // }, []);

  const fetchCats = (val, setStateCallback) => {
    getList("categories", { "filter[name]": val })
      .then((res) => {
        setStateCallback(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {};
  };

  const handleSearch = (val) => {
    if (val && val.length >= 3) {
      fetchCats(val, setCategories);
    } else {
      setCategories([]);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);

    try {
      let url = "items";
      if (update) {
        values = { ...values, _method: "PATCH" };
        url = `items/${state.id}`;
      }
      values = { ...values, category_id: state.category_id };
      const res = await apiPost(url, values);
      console.log(res);
      message.success("Item saved!");
      form.resetFields();
      onClose();
    } catch (error) {
      Object.keys(error.errors).forEach((key) => {
        form.setFields([
          {
            name: key,
            errors: [error.errors[key]],
          },
        ]);
      });
      message.error(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const { Option } = Select;

  let options = categories.map((cat) => {
    return (
      <Option key={cat.id} value={cat.id}>
        {cat.name}
      </Option>
    );
  });

  return (
    <>
      <Drawer
        title={title}
        width={600}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          ref={formRef}
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={state}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Item name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  placeholder="Dell Inspiron 15 3000 Laptop"
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, name: e.target.value };
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={["category", "name"]}
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a category"
                  filterOption={false}
                  onSearch={handleSearch}
                  notFoundContent={null}
                  onChange={(val) =>
                    setState((state) => {
                      return { ...state, category_id: val };
                    })
                  }
                >
                  {options}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="purchased_on"
                label="Purchased date"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="stock_location"
                label="Stock location"
                rules={[]}
              >
                <Input
                  placeholder="Stock Room 1, Shelf A-52"
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, stock_location: e.target.value };
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
