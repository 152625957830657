import axios from "axios";
import { useState } from "react";

export default function useAuthed(){

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        return JSON.parse(tokenString);
    };

    const isAuthed = () => {
        return getToken() !== null;
    };

    const [authed, setAuthed] = useState(isAuthed());

    const updateToken = ( token = null ) => {
        if(token === null){
            deleteToken();
        }
        else
        {
            saveToken(token);
            // axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
        }
    };

    const saveToken = (userToken) => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setAuthed(isAuthed());
        // axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
    };

    const deleteToken = () => {
        localStorage.removeItem('token')
        delete axios.defaults.headers.common["Authorization"];
        setAuthed(isAuthed());

  
    };

    return {
        setAuthed: updateToken,
        getToken,
        authed
    };
}