import { Button, Col, message, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { getList } from "../services/list";
import FilterComponent from "./sub/FilterComponent";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

const filterOptions = [
  {
    value: "filter[code]",
    label: "Code",
  },
  {
    value: "filter[name]",
    label: "Name",
  },
];

export default function Users() {
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [filterState, setFilterState] = useState({});
  const [formDrawer, setFormDrawer] = useState(false);
  const [update, setUpdate] = useState(false);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active) => <>{is_active ? "Active" : "In-active"}</>,
    },
    {
      title: "",
      key: "action",
      className: "has-actions",
      render: (record) => (
        <>
          <Button.Group className="actions">
            <Button
              type="link"
              onClick={(e) => handleFormDrawerOpenForUpdate(record)}
            >
              <EditOutlined />
              Edit
            </Button>
            <Button type="link" onClick={(e) => console.log(record)}>
              <EditOutlined />
              Delete
            </Button>
          </Button.Group>
        </>
      ),
    },
  ];

  const fetch = async (params = {}) => {
    let mounted = true;
    setLoading(true);

    getList("users", {
      per_page: 7,
      ...params,
      ...filterState,
      sort: "-id",
    })
      .then((items) => {
        if (mounted) {
          console.log(items);
          setList(items.data);

          setPagination((prev) => {
            return {
              ...prev,
              current: items.current_page,
              pageSize: items.per_page,
              total: items.total,
            };
          });
        }
      })
      .catch((error) => {
        message.error(`${error.statusCode}: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => (mounted = false);
  };
  useEffect(() => {
    fetch({
      sort: "-id",
    });
  }, []);

  const openFormDrawer = () => {
    setSelectedUser({});
    setFormDrawer(true);
  };

  const handleFormDrawerClose = () => {
    setFormDrawer(false);
    setUpdate(false);
    setSelectedUser({});
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
    });
  };

  const handleFormDrawerOpenForUpdate = (record) => {
    setUpdate(true);
    setSelectedUser(record);
    setFormDrawer(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination((prev) => {
      return {
        ...prev,
        ...pagination,
      };
    });
    console.log("filters", filters);
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  return (
    <>
      <h1>Users</h1>
      <Row style={{ marginBottom: "1rem" }}>
        <Col xs={8}>
          <FilterComponent
            filterOptions={filterOptions}
            defaultFilterOption="filter[code]"
            onFilter={fetch}
            filterState={filterState}
            setFilterState={setFilterState}
          />
        </Col>
        <Col xs={10} />
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button
            type="secondary"
            style={{ fontWeight: "bold" }}
            onClick={() => openFormDrawer()}
          >
            <PlusOutlined />
            Add New
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={list}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
      />
      {/* <CategoryFormDrawer
        onClose={handleFormDrawerClose}
        visible={categoryFormDrawer}
        update={update}
        category={selectedCategory}
      /> */}
    </>
  );
}
