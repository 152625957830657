import React, { useContext } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import useAuthed from "./hooks/useAuthed";
import { AuthedContext } from "./contexts/AuthedContext";

import "antd/dist/antd.css";
import "./App.css";
import axios from "axios";
import Categories from "./components/Categories";
import AdminLayout from "./components/AdminLayout";
import Items from "./components/Items";
import Lent from "./components/Lent";
import Employees from "./components/Employees";
import Users from "./components/Users";

function PrivateRoute({ component: Component, ...rest }) {
  const { authed, getToken, setAuthed } = useContext(AuthedContext);
  axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      if (status === 401) {
        setAuthed();
      }
      return Promise.reject(error);
    }
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function App(props) {
  const { authed, setAuthed, getToken } = useAuthed();

  return (
    <AuthedContext.Provider value={{ authed, setAuthed, getToken }}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/login"
            render={() =>
              authed === true ? (
                <Redirect to={{ pathname: "/dashboard" }} />
              ) : (
                <Login />
              )
            }
          />

          <AdminLayout>
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/categories" component={Categories} />
            <PrivateRoute path="/lent" component={Lent} />
            <PrivateRoute path="/items" component={Items} />
            <PrivateRoute path="/employees" component={Employees} />
            <PrivateRoute path="/users" component={Users} />
            <PrivateRoute path="/" exact component={Dashboard} />
          </AdminLayout>
        </Switch>
      </BrowserRouter>
    </AuthedContext.Provider>
  );
}

export default App;
