import axios from "axios";

export async function logout(){

    return axios
    .post(`${process.env.REACT_APP_BASE_URL}/logout`)
    .then(response => {
        return response.data.token
    })
    .catch(error => {
        console.error(error)
        return Promise.reject(error.response.data)
    });
   
}