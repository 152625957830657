import React, { useEffect, useState } from "react";
import { getList } from "../services/list";
import { Button, Checkbox, Col, Input, message, Modal, Row, Table } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import CategoryFormDrawer from "./sub/CategoryFormDrawer";
import FilterComponent from "./sub/FilterComponent";
import { flushSync } from "react-dom";

const filterOptions = [
  {
    value: "filter[code]",
    label: "Code",
  },
  {
    value: "filter[name]",
    label: "Category Name",
  },
  {
    value: "filter[parent.name]",
    label: "Parent Name",
  },
];

export default function Categories() {
  const [list, setList] = useState([]);
  const [state, setState] = useState({ loading: false, pagination: {} });
  const [selectedCategory, setSelectedCategory] = useState({});
  const [filterState, setFilterState] = useState({});
  const [categoryFormDrawer, setCategoryFormDrawer] = useState(false);
  const [update, setUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Parent Category",
      dataIndex: ["parent", "name"],
      key: ["parent", "name"],
    },
    {
      title: "Has Lendable Items",
      dataIndex: "is_lendable",
      key: "is_lendable",
      render: (is_lendable) => <>{is_lendable ? "Yes" : "No"}</>,
    },
    {
      title: "",
      key: "action",
      className: "has-actions",
      render: (record) => (
        <>
          <Button.Group className="actions">
            <Button
              type="link"
              onClick={(e) => handleCategoryFormDrawerOpenForUpdate(record)}
            >
              <EditOutlined />
              Edit
            </Button>
            <Button type="link" onClick={(e) => console.log(record)}>
              <EditOutlined />
              Delete
            </Button>
          </Button.Group>
        </>
      ),
    },
  ];

  const fetch = async (params = {}) => {
    let mounted = true;
    setState((prev) => {
      return { ...prev, loading: true };
    });

    getList("categories", {
      per_page: 7,
      include: "parent",
      ...params,
      // ...filterState,
      sort: "-id",
    })
      .then((items) => {
        if (mounted) {
          console.log(items);
          setList(items.data);

          setState((prev) => {
            return {
              ...prev,
              pagination: {
                current: items.current_page,
                pageSize: items.per_page,
                total: items.total,
              },
            };
          });
        }
      })
      .catch((error) => {
        message.error(`${error.statusCode}: ${error.message}`);
      })
      .finally(() => {
        setState((prev) => {
          return { ...prev, loading: false };
        });
      });
    return () => (mounted = false);
  };

  useEffect(() => {
    fetch({
      sort: "-id",
    });
  }, []);

  const handleModalCancel = () => {
    setShowModal(() => {
      return false;
    });
  };

  const clearFilters = () => {
    // flushSync(() => {
    //   setFilterState(() => {});
    // });
    setFilterState(() => {});
    fetch({});
  };

  const openCategoryFormDrawer = () => {
    setSelectedCategory({});
    setCategoryFormDrawer(true);
  };

  const handleFormDrawerClose = () => {
    setCategoryFormDrawer(false);
    setUpdate(false);
    setSelectedCategory({});
    fetch({
      per_page: state.pagination.pageSize,
      page: state.pagination.current,
    });
  };

  const handleCategoryFormDrawerOpenForUpdate = (record) => {
    setUpdate(true);
    setSelectedCategory(record);
    setCategoryFormDrawer(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setState((prev) => {
      return {
        ...prev,
        pagination: pagination,
      };
    });
    console.log("filters", filters);
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filterState,
    });
  };

  // const removeFilters =
  //   Object.keys(filterState).length > 0 ? (
  //     <Row style={{ marginBottom: "1rem" }}>
  //       <Col xs={12}>
  //         Applied Filters:{" "}
  //         <Button type="link" onClick={() => clearFilters()}>
  //           Remove Filters
  //         </Button>
  //       </Col>
  //     </Row>
  //   ) : (
  //     <>No Filters</>
  //   );

  return (
    <>
      <h1>Categories</h1>
      <Row style={{ marginBottom: "1rem" }}>
        <Col xs={8}>
          <FilterComponent
            filterOptions={filterOptions}
            defaultFilterOption="filter[code]"
            onFilter={fetch}
            filterState={filterState}
            setFilterState={setFilterState}
          />
        </Col>
        <Col xs={10}>
          <Button
            type="link"
            onClick={() =>
              setShowModal(() => {
                return true;
              })
            }
          >
            Advanced Filters
          </Button>

          <Button type="link" onClick={() => clearFilters()}>
            Remove Filters
          </Button>
        </Col>

        <Col xs={6} style={{ textAlign: "right" }}>
          <Button
            type="secondary"
            style={{ fontWeight: "bold" }}
            onClick={() => openCategoryFormDrawer()}
          >
            <PlusOutlined />
            Add New
          </Button>
        </Col>
      </Row>

      <Table
        dataSource={list}
        columns={columns}
        rowKey="id"
        loading={state.loading}
        pagination={state.pagination}
        onChange={handleTableChange}
      />
      <CategoryFormDrawer
        onClose={handleFormDrawerClose}
        visible={categoryFormDrawer}
        update={update}
        category={selectedCategory}
      />

      <Modal
        title="Category Advanced Filter"
        visible={showModal}
        onOk={() => {
          fetch({ ...filterState });
          setShowModal(false);
        }}
        onCancel={handleModalCancel}
        okText="Apply Filters"
        destroyOnClose={true}
      >
        <Row style={{ marginBottom: "1rem" }}>
          <Col xs={12}>
            <Input
              placeholder="Parent Category Name"
              onChange={(e) =>
                setFilterState((filters) => {
                  return { ...filters, "filter[parent.name]": e.target.value };
                })
              }
            />
          </Col>
          <Col xs={12}>
            <Input
              placeholder="Sub Category Name"
              onChange={(e) =>
                setFilterState((filters) => {
                  return { ...filters, "filter[name]": e.target.value };
                })
              }
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "1rem" }}>
          <Col xs={12}>
            <Input
              placeholder="Parent Category Code"
              onChange={(e) =>
                setFilterState((filters) => {
                  return { ...filters, "filter[parent.code]": e.target.value };
                })
              }
            />
          </Col>
          <Col xs={12}>
            <Input
              placeholder="Sub Category Code"
              onChange={(e) =>
                setFilterState((filters) => {
                  return { ...filters, "filter[code]": e.target.value };
                })
              }
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <Col xs={12}>
            <Checkbox
              onClick={(e) =>
                setFilterState((filters) => {
                  return {
                    ...filters,
                    "filter[is_lendable]": e.target.checked,
                  };
                })
              }
            >
              Only categories with lendable items
            </Checkbox>
          </Col>
          <Col xs={12}></Col>
        </Row>
      </Modal>
    </>
  );
}
