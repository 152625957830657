import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { getList } from "../../services/list";
import { apiPost } from "../../services/postService";

export default function CategoryFormDrawer({
  onClose,
  visible,
  update,
  category,
}) {
  const [form] = Form.useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const title = update ? "Updating Category" : "Adding New Category";

  useEffect(() => {
    setState(category);
    console.log("cate", category);
    form.resetFields();
    (() => {
      form.setFieldsValue(category);
    })();
  }, [setState, category, form]);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      let url = "categories";
      if (update) {
        values = { ...values, _method: "PATCH" };
        url = `categories/${state.id}`;
      }
      const res = await apiPost(url, values);
      console.log(res);
      message.success("Category saved!");
      form.resetFields();
      onClose();
    } catch (error) {
      Object.keys(error.errors).forEach((key) => {
        form.setFields([
          {
            name: key,
            errors: [error.errors[key]],
          },
        ]);
      });
      message.error(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCats = () => {
      getList("categories")
        .then((res) => {
          setCategories(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {};
    };
    fetchCats();
  }, []);

  const { Option } = Select;

  const options = categories.map((cat) => {
    return (
      <Option key={cat.id} value={cat.id}>
        {cat.name}
      </Option>
    );
  });

  return (
    <>
      <Drawer
        title={title}
        width={600}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={state}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Category name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  placeholder="Laptops"
                  defaultValue={state.name}
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, name: e.target.value };
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="parent_id" label="Parent category" rules={[]}>
                <Select
                  showSearch
                  placeholder="Select a category"
                  defaultActiveFirstOption={false}
                  // showArrow={false}
                  // filterOption={false}
                  // onSearch={this.handleSearch}
                  onChange={(val) =>
                    setState((state) => {
                      return { ...state, parent_id: val };
                    })
                  }
                >
                  {options}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="is_lendable"
                label="Category has lendable items"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
