import axios from "axios";

export async function login(credentials) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/login`, credentials)
    .then((response) => {
      // console.log(response.data)
      return response.data.token;
      // console.log('Well done!', response.data.data);
      // console.log('User profile', response.data.user);
      // console.log('User token', response.data.jwt);
    })
    .catch((error) => {
      // Handle error.
      // console.log("error form service", error.response.data)
      return Promise.reject(error);
    });
}
