import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export default function Loading(){
    // need to deveop. put here as a test
    return (
        <Row justify="center" align="middle">
            <Col span={4}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </Col>
        </Row>
    )
}