import axios from "axios"


export function apiPost(resource, params={}) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/${resource}`, params)
        .then(res => {
            return res.data
        })
        .catch( err => {
            console.log(err)
            return Promise.reject(err.response.data)
        })
    }