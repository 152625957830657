import React, { createRef, useEffect, useState } from "react";
import { getList } from "../services/list";
import { Button, Col, Input, message, Row, Table, Select, Divider } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { IoReturnUpForwardOutline } from "react-icons/io5";
import LendItemDrawer from "./sub/LendItemDrawer";

import { EditOutlined } from "@ant-design/icons";
import ItemFormDrawer from "./sub/ItemFormDrawer";
import ReturnDrwaer from "./sub/ReturnDrawer";

export default function Items() {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [item, setItem] = useState({});
  const [returnItem, setReturnItem] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});
  const [update, setUpdate] = useState(false);

  const [lendDrawerOpen, setLendDrawerOpen] = useState(false);
  const [returnDrawerVisible, setReturnDrawerVisible] = useState(false);
  const [itemFormDrawer, setItemFormDrawer] = useState(false);

  const handleLendDrawerClose = () => {
    setLendDrawerOpen(false);
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
    });
    setSelectedItem({});
  };

  const handleReturnDrawerClose = () => {
    setReturnDrawerVisible(false);
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
    });
    setReturnItem({});
  };

  const handleItemDrawerOpen = () => {
    setSelectedItem({});
    setItemFormDrawer(true);
  };

  const handleItemFormDrawerClose = () => {
    setItemFormDrawer(false);
    setUpdate(false);
    setSelectedItem({});
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
    });
  };

  const handleItemFormDrawerOpenForUpdate = (record) => {
    setUpdate(true);
    setSelectedItem(record);
    setItemFormDrawer(true);
  };

  const tableLoading = {
    spinning: loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  };

  const handleLend = async (record) => {
    setLendDrawerOpen(true);
    setItem(record);
    console.log(record);
  };
  const handleReturn = async (record) => {
    setReturnDrawerVisible(true);
    let rItem = Object.assign({}, record.active_borrow);
    rItem["item"] = record;
    setReturnItem(rItem);
    console.log(rItem);
  };

  const columns = [
    {
      title: "Inventory No",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (category) => (
        <>
          <div>{category.name}</div>
          <div>
            <small>{category.parent.name}</small>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "active_borrow",
      render: (active_borrow, row) => (
        <>
          {active_borrow === null
            ? `In stock`
            : `Borrowed by ${active_borrow.employee.name} (${active_borrow.employee.nic})`}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "active_borrow",
      className: "has-actions",
      render: (active_borrow, record) => (
        <>
          <Button.Group className="actions">
            {/* <div className="actions"> */}
            {record.category.is_lendable === 1 && (
              <>
                {active_borrow === null ? (
                  <Button type="link" onClick={(e) => handleLend(record)}>
                    {/* <SwapLeftOutlined /> */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IoReturnUpForwardOutline /> &nbsp;
                      <span>Lend</span>
                    </div>
                  </Button>
                ) : (
                  <Button type="link" onClick={(e) => handleReturn(record)}>
                    {/* <SwapRightOutlined /> */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IoReturnUpForwardOutline /> &nbsp;
                      <span>Return</span>
                    </div>
                  </Button>
                )}
              </>
            )}

            <Button
              type="link"
              onClick={(e) => handleItemFormDrawerOpenForUpdate(record)}
            >
              <EditOutlined />
              Edit
            </Button>

            <Button
              type="link"
              onClick={(e) => handleItemFormDrawerOpenForUpdate(record)}
            >
              <EditOutlined />
              Details
            </Button>
            {/* </div> */}
          </Button.Group>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);

    console.log("filters", filters);
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  const fetch = async (params = {}) => {
    let mounted = true;
    setLoading(true);
    getList("items", {
      include: "category.parent,activeBorrow.employee",
      sort: "-id",
      ...params,
      ...filterState,
    })
      .then((items) => {
        if (mounted) {
          console.log(items);
          setList(items.data);
          setPagination({
            current: items.current_page,
            pageSize: items.per_page,
            total: items.total,
          });
        }
      })
      .catch((error) => {
        message.error(`${error.statusCode}: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => (mounted = false);
  };

  useEffect(() => {
    fetch({
      per_page: 7,
    });
  }, []);

  const { Search } = Input;
  const { Option } = Select;
  const [searchBoxState, setSearchBoxState] = useState({
    value: "filter[code]",
    placeholder: `Search for Inventory No`,
  });
  const [filterState, setFilterState] = useState({});

  const searchRef = createRef();
  const filterList = (
    <Select
      defaultValue="filter[code]"
      onSelect={(value, options) => onFilterSelect(value, options)}
      className="select-before"
    >
      <Option value="filter[code]" label="Inventory No">
        Inventory No
      </Option>
      <Option value="filter[name]" label="Item Name">
        Item Name
      </Option>
      <Option value="filter[category.name]" label="Category Name">
        Sub Category Name
      </Option>
      <Option value="filter[category.parent.name]" label="Category Name">
        Parent Category Name
      </Option>
    </Select>
  );
  const onFilterSelect = (val, options) => {
    setSearchBoxState((state) => {
      return { value: val, placeholder: `Search for ${options.label}` };
    });
    const newFilterState = {};
    newFilterState[val] = Object.values(filterState)[0];
    setFilterState(newFilterState);
    searchRef.current.focus();
  };

  const onFilterChange = (value) => {
    const val = searchBoxState.value;
    const param = {};
    param[val] = value;
    setFilterState(param);
  };
  const onFilterEnter = async () => {
    console.log(filterState);

    fetch({
      per_page: 7,
      ...filterState,
    });
  };

  return (
    <>
      <h1>Items</h1>

      <Row style={{ marginBottom: "1rem" }}>
        <Col xs={8}>
          <Search
            ref={searchRef}
            placeholder={searchBoxState.placeholder}
            style={{ border: "none" }}
            loading={searchLoading}
            addonBefore={filterList}
            enterButton="Filter"
            autoFocus={true}
            onChange={(e) => onFilterChange(e.target.value)}
            onPressEnter={() => onFilterEnter()}
            onSearch={() => onFilterEnter()}
          />
        </Col>
        <Col xs={10}></Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button
            type="secondary"
            style={{ fontWeight: "bold" }}
            onClick={() => handleItemDrawerOpen()}
          >
            <PlusOutlined />
            Add Item
          </Button>
        </Col>
      </Row>

      <Table
        rowKey="id"
        dataSource={list}
        columns={columns}
        loading={tableLoading}
        pagination={pagination}
        onChange={handleTableChange}
      />

      {Object.keys(item).length > 0 && (
        <LendItemDrawer
          onClose={handleLendDrawerClose}
          visible={lendDrawerOpen}
          item={item}
        />
      )}

      {Object.keys(returnItem).length > 0 && (
        <ReturnDrwaer
          onClose={handleReturnDrawerClose}
          visible={returnDrawerVisible}
          item={returnItem}
        />
      )}

      <ItemFormDrawer
        onClose={handleItemFormDrawerClose}
        visible={itemFormDrawer}
        update={update}
        item={selectedItem}
      />
    </>
  );
}
