import React, { useEffect, useState } from "react";
import MenuItems from "./MenuItems";
import Logout from "./Logout";

import { Layout, Tooltip, Button, Row, Col, Input } from "antd";
import { MenuOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";

import "antd/dist/antd.css";
import "../App.css";
import Loading from "./Loading";

const { Header, Sider, Content } = Layout;

function MenuCollapseButton({ collapsed, setCollapsed }) {
  return (
    <Tooltip title="Fold/Unfold menu">
      <Button
        onClick={() => setCollapsed(!collapsed)}
        className="trigger"
        shape="circle"
        icon={collapsed ? <MenuOutlined /> : <MoreOutlined />}
      />
    </Tooltip>
  );
}

function AdminLayout({ children }) {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    document.title = "Inventory Management System - MBS";
  });

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed} width="230px">
        <div
          style={{
            // height:"auto",
            // backgroundColor:"#1890ff",
            textAlign: "center",
            padding: "20px 10px 0px 0px",
            color: "rgb(91, 98, 111)",
            marginBottom: "20px",
          }}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Emblem_of_Maldives.svg/375px-Emblem_of_Maldives.svg.png"
            alt="Government Emblem"
            style={{ width: "70px", padding: "10px" }}
          />
          <br />
          Inventory Management
          <br />
          <span>Maldives Bureau of Statistics</span>
        </div>

        <MenuItems />
      </Sider>

      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ paddingLeft: 10 }}>
          <Row>
            <Col xs={2}>
              <MenuCollapseButton
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
            </Col>
            <Col xs={18}>
              <Input
                size="large"
                placeholder="Search for something"
                prefix={<SearchOutlined style={{ color: "rgba(0,0,0,0.3)" }} />}
                style={{ border: "none" }}
              />
            </Col>
            <Col xs={4} style={{ textAlign: "right" }}>
              <Logout />
            </Col>
          </Row>
        </Header>

        <Content
          style={{
            // margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default AdminLayout;
