import { Button, Col, message, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { getList } from "../services/list";
import LocalDataTable from "./sub/LocalDataTable";
import ReturnDrwaer from "./sub/ReturnDrawer";
import { LoadingOutlined } from "@ant-design/icons";
import FilterComponent from "./sub/FilterComponent";

const filterOptions = [
  {
    value: "filter[employee.name]",
    label: "Staff Name",
  },
  {
    value: "filter[item.code]",
    label: "Inventory No",
  },
];

export default function Lent() {
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState({});

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filterState, setFilterState] = useState({});

  const tableLoading = {
    spinning: loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  };
  const fetch = async (params = {}) => {
    let mounted = true;
    setLoading(true);
    getList("borrows", {
      include: "item,employee",
      sort: "-id",
      "filter[status]": "borrowed",
      ...params,
    })
      .then((items) => {
        if (mounted) {
          console.log(items);
          setList(items.data);
          setPagination({
            current: items.current_page,
            pageSize: items.per_page,
            per_page: items.per_page,
            total: items.total,
          });
        }
      })
      .catch((error) => {
        message.error(`${error.statusCode}: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => (mounted = false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  useEffect(() => {
    fetch({
      per_page: 7,
    });
  }, []);

  const handleDrawerClose = () => {
    setVisible(false);
    fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
    });
  };

  const columns = [
    {
      title: "Item",
      dataIndex: ["item", "name"],
      key: ["item", "name"],
      render: (item, record) => {
        return (
          <>
            ({record.item.code}) {item}
          </>
        );
      },
    },
    {
      title: "Staff",
      dataIndex: ["employee", "name"],
      key: ["employee", "name"],
    },
    {
      title: "Borrowed",
      dataIndex: "request_confirmed_at",
      key: "request_confirmed_at",
      render: (request_confirmed_at, record) => (
        <>
          {request_confirmed_at ? (
            <div>
              {request_confirmed_at}
              <br /> <small>{record.notes}</small>
            </div>
          ) : (
            <div>Failed request</div>
          )}
        </>
      ),
    },
    {
      title: "Returned",
      dataIndex: "returned_at",
      render: (returned_at, record) => (
        <>
          {returned_at ? (
            <div>
              {returned_at} <br /> <small>{record.return_notes}</small>
            </div>
          ) : record.request_confirmed_at ? (
            <Button type="primary" onClick={(e) => handleReturn(record)}>
              Return
            </Button>
          ) : (
            <Button type="danger" onClick={(e) => {}}>
              Delete
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleReturn = async (record) => {
    setVisible(true);
    setItem(record);
    console.log(record);
  };

  return (
    <>
      <h1>Borrowed Items</h1>
      <Row style={{ marginBottom: "1rem" }}>
        <Col xs={8}>
          <FilterComponent
            filterOptions={filterOptions}
            defaultFilterOption="filter[employee.name]"
            onFilter={fetch}
            filterState={filterState}
            setFilterState={setFilterState}
          />
        </Col>
      </Row>
      <Table
        rowKey="id"
        dataSource={list}
        columns={columns}
        loading={tableLoading}
        pagination={pagination}
        onChange={handleTableChange}
      />
      <ReturnDrwaer onClose={handleDrawerClose} visible={visible} item={item} />
    </>
  );
}
