import {
  Form,
  Button,
  Col,
  Drawer,
  Input,
  Row,
  AutoComplete,
  message,
} from "antd";

import { useEffect, useState } from "react";
import { getList } from "../../services/list";
import { apiPost } from "../../services/postService";

const { Option } = AutoComplete;

export default function LendItemDrawer({ onClose, visible, item }) {
  const [employees, setEmployees] = useState([]);
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);

  const [requestForm] = Form.useForm();
  const [otpForm] = Form.useForm();

  useEffect(() => {
    setState({ item_id: item.id });
    (() => {
      return requestForm.resetFields();
    })();
  }, [item, setState, requestForm]);

  useEffect(() => {
    setVerifyOtp(false);
  }, [setVerifyOtp]);

  const fetch = async (params = {}) => {
    let mounted = true;
    setLoading(true);

    getList("employees", { ...params })
      .then((items) => {
        if (mounted) {
          setEmployees(items.data);
          if (items.length <= 0) {
            setState((state) => {
              const stateCopy = { ...state };
              if (stateCopy.employee_id !== undefined)
                delete stateCopy.employee_id;
              return stateCopy;
            });
          }
        }
      })
      .catch((error) => {
        message.error(`${error.statusCode}: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => (mounted = false);
  };

  const handleOtpRequest = async () => {
    if (state.employee_id === undefined)
      return message.error("Pleaes select employee");

    setRequestLoading(true);

    try {
      const res = await apiPost("borrows", state);
      setState((state) => {
        return { ...state, ...{ borrow_id: res.id } };
      });
      setVerifyOtp(true);
      requestForm.resetFields();
    } catch (error) {
      message.error(`${error.statusCode}: ${error.message}`);
      console.error(error);
    } finally {
      setRequestLoading(false);
    }
  };

  const handleOtpCancel = () => {
    setVerifyOtp(false);
    requestForm.resetFields();
  }
  const handleOtpVerify = async () => {
    if (state.otp === undefined || state.borrow_id === undefined) {
      return message.error(
        "There were errors. Make sure all fields are filled"
      );
    }

    setRequestLoading(true);
    try {
      const res = await apiPost(`borrows/${state.borrow_id}/verify`, state);
      setVerifyOtp(false);
      message.success("Borrow Request Verified");
      otpForm.resetFields();
      onClose();
    } catch (error) {
      Object.keys(error.errors).forEach((key) => {
        otpForm.setFields([
          {
            name: key,
            errors: [error.errors[key]],
          },
        ]);
      });
      message.error(`${error.message}`);
      console.error(error);
    } finally {
      setRequestLoading(false);
    }
  };

  const onSelect = () => {
    setState((state) => {
      return { ...state, employee_id: employees[0].id };
    });
  };

  const handleSearch = (value) => {
    fetch({
      "filter[name]": value,
    });
  };


  return (
    <Drawer
      title="Lend item"
      width={600}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Row>
        <Col span={5}>Item Name</Col>
        <Col span={12}><strong>{item.name}</strong></Col>
      </Row>
      <Row>
        <Col span={5}>Item Code</Col>
        <Col span={12}><strong>{item.code}</strong></Col>
      </Row>
      <Row>
        <Col span={5}>Item Category</Col>
        <Col span={12}><strong>{item.category.name}</strong></Col>
      </Row>
     
      <br />

      {verifyOtp ? (
        <Form form={otpForm}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "please enter otp",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, otp: e.target.value };
                    })
                  }
                  placeholder="Verification Code"
                />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 16 }}>
                <Button
                  type="primary"
                  loading={requestLoading}
                  htmlType="submit"
                  onClick={() => handleOtpVerify()}
                >
                  Verify Code
                </Button>
                <Button
                  style={{marginLeft:"1rem"}}
                  danger
                  type="text"
                  onClick={() => handleOtpCancel()}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form layout="vertical" form={requestForm}>
          <Row gutter={16}>
            <Col span={24}>
              <AutoComplete
                allowClear
                rules={[
                  {
                    required: true,
                    message: "please enter employee name",
                  },
                ]}
                label="Employee"
                dropdownMatchSelectWidth={252}
                placeholder="Search for employee name"
                style={{ width: "100%" }}
                onSelect={onSelect}
                onSearch={(value) => handleSearch(value)}
                initialValue=""
              >
                {employees.map((employee) => (
                  <Option key={employee.id} value={employee.name}>
                    {employee.name} ({employee.nic})
                  </Option>
                ))}
                {/* <Input.Search size="large" placeholder="Employee name or NIC"  /> */}
              </AutoComplete>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} style={{ paddingTop: "20px" }}>
              <Form.Item name="notes">
                <Input.TextArea
                  rows={4}
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, notes: e.target.value };
                    })
                  }
                  placeholder="Please enter any notes deemed necessary"
                />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 16 }}>
                <Button
                  type="primary"
                  loading={requestLoading}
                  htmlType="submit"
                  onClick={() => handleOtpRequest()}
                >
                  Request Verification Code
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Drawer>
  );
}
